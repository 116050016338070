import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaCheckDouble } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const Transactionhistory = ({ isUserNumberVisible }) => {
  const [user, setUser] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
    setPageNumber(0);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  //user for searching..
  const [searchList, setSearchList] = useState(0);
  const [searchType, setSearchType] = useState(0);
  const [findByStatus, setFindByStatus] = useState(0);
  const [totals, setTotals] = useState({
    paid: 0,
    pending: 0,
    failed: 0,
  });

  //   searching handler
  const searchHandler = (event) => {
    let key = event.target.value;
    if (searchType === "phone" && !Number(key) && key.length >= 1) {
      alert("Please enter a valid phone number");
      return;
    }
    setSearchList(key);
    setFindByStatus(0);
    document.getElementById("findByStatus").value = "0";
  };
  //   search by status handler
  const searchByStatus = (event) => {
    let key = event.target.value;
    setFindByStatus(key);
    setSearchList(0);
    setSearchType(0);
    let selectType = document.getElementById("searchType");
    selectType.value = "0";
  };

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };
  const createDownloadData = () => {
    const table1 = [
      {
        A: "Name",
        B: "Mobile",
        C: "Amount",
        D: "Status",
        // E: "KYC",
      },
    ];
    user.forEach((row) => {
      table1.push({
        A: row.userId ? row.userId.name : "",
        B:
          row.userId && isUserNumberVisible
            ? row.userId.phone
            : "+91XXXXXXXXXX",
        C: row.amount ? row.amount : "",
        D: row.status ? row.status : "",
        // E: row.verified ? row.verified : "",
      });
    });
    const ws = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "userDepositDetails.xlsx");
  };

  const downloadPDF = () => {
    // Prepare table data
    let table1 = user?.map((row) => ({
      A: row.userId ? row.userId.name : "",
      B:
        row.userId && isUserNumberVisible
          ? String(row.userId.phone)
          : "+91XXXXXXXXXX",
      C: row.amount ? String(row.amount) : "",
      D: row.status || "",
    }));

    // Generate PDF
    const doc = new jsPDF();
    const columns = [
      { header: "Name", dataKey: "A" },
      { header: "Mobile", dataKey: "B" },
      { header: "Amount", dataKey: "C" },
      { header: "Status", dataKey: "D" },
    ];

    // Use autoTable to draw the table
    doc.autoTable({
      head: [columns.map((col) => col.header)], // Set table headers
      body: table1.map((row) => [row.A, row.B, row.C, row.D]),
      startY: 20, // Set the starting Y position for rendering the table
    });

    // Save the generated PDF
    doc.save("userDepositDetails.pdf");
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // console.log(
    //   "Entries",
    //   "pageNo:-",
    //   pageNumber,
    //   "SerachList:-",
    //   searchList,
    //   "Search Type:-",
    //   searchType,
    //   "FindByStatus",
    //   findByStatus
    // );
    axios
      .get(
        baseUrl +
          `txn/deposit/all?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&_status=${findByStatus}`,
        { headers }
      )
      .then((res) => {
        console.log("response data of transaction api is---------", res.data);
        if (
          res.data.message &&
          searchType === "phone" &&
          searchList.length <= 10
        ) {
          return;
        } else if (!res.data.admin) {
          setUser(0);
          return;
        }
        console.log("response data is :- ", res?.data?.admin);
        setUser(res?.data?.admin);
        setNumberOfPages(res.data.totalPages);
        //$('table').dataTable();
        let totalsData = {
          paid: 0,
          pending: 0,
          failed: 0,
        };

        res.data.admin?.forEach((item) => {
          if (item.status === "PAID") {
            totalsData.paid += item.amount;
          } else if (item.status === "Pending") {
            totalsData.pending += item.amount;
          } else if (item.status === "FAILED") {
            totalsData.failed += item.amount;
          }
        });
        // console.log("Data at transaction page is :-------------", totalsData);
        setTotals(totalsData);
      })
      .catch((e) => console.log(e));
  };

  const checkdeposit = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `depositupipay/response`,
        { order_id, order_token, pay_date },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        profle();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const universalCheckDeposit = (
    paymentGatway,
    order_id,
    order_token,
    pay_date
  ) => {
    //alert(paymentGatway);
    if (paymentGatway == "razorpay") {
      checkrazorpayPayment(order_id, order_token, pay_date);
    } else if (paymentGatway == "decentropay") {
      checkdecentroupipay(order_id, order_token, pay_date);
    } else if (paymentGatway == "pinelab") {
      checkPinelabpay(order_id, order_token, pay_date);
    } else if (paymentGatway == "upigateway") {
      checkupigatewaypay(order_id, order_token, pay_date);
    } else if (!paymentGatway) {
      withdrowFail(order_id);
    } else {
      alert("Nothing found");
    }
  };

  const checkrazorpayPayment = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaycheck/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        profle();
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const checkdecentroupipay = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `decentroupipay/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        profle();
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const checkupigatewaypay = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `depositupipay/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        profle();
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const checkPinelabpay = (order_id, order_token, pay_date) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `pinelabdesposit/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        profle();
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const withdrowPass = (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to success this deposit?"
    );
    if (confirm) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `userdipositupdate/${id}`,
          {
            status: "SUCCESS",
          },
          { headers }
        )
        .then((res) => {
          profle();
          alert("deposit successfully done");
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  const withdrowFail = (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to failed this deposit?"
    );
    if (confirm) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `userdipositupdate/${id}`,
          {
            status: "FAILED",
          },
          { headers }
        )
        .then((res) => {
          profle();
          alert("deposit successfully reject");
          //console.log(res);
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const newdateFormat = (e) => {
    let today = new Date(e);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, searchList, searchType, findByStatus]);

  if (user === undefined) {
    return null;
  }
  const boxStyle = {
    padding: "20px",
    margin: "40px 20px",
    borderRadius: "5px",
    color: "#fff",
    fontSize: "20px",
    display: "inline-block",
    minWidth: "250px",
    textAlign: "center",
  };

  const greenBox = {
    ...boxStyle,
    backgroundColor: "#28a745", // Green
  };

  const orangeBox = {
    ...boxStyle,
    backgroundColor: "#ff8c00", // Orange
  };

  const redBox = {
    ...boxStyle,
    backgroundColor: "#dc3545", // Red
  };
  return (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card "
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body text-light"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4
                className="card-title mt-4"
                // style={{ color: "white" }}
              >
                Deposit History
              </h4>
              {/* data download button */}
              <div className="download-button">
                <button
                  onClick={() => {
                    createDownloadData();
                  }}
                  className="btn btn-primary "
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#0056b3";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#007bff";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <RiFileExcel2Line style={{ fontSize: "24px" }} />
                </button>

                {/* pdf download button */}
                <button
                  onClick={() => {
                    // createPDF();
                    downloadPDF();
                  }}
                  className="btn btn-danger"
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#841f29";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#dc3545";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <FaRegFilePdf style={{ fontSize: "24px" }} />
                </button>
              </div>
              {/* searching */}
              <div
                className="row d-flex  justify-content-center justify-content-sm-between align-items-center mb-4"
                style={{
                  gap: "10px",

                  // border: "2px solid green",
                  padding: "0px 14px",
                }}
              >
                <select
                  className="form-control col-sm-3 bg-light text-dark commoncss-custom-class-width"
                  id="searchType"
                  name="searchtype"
                  onChange={(e) => {
                    setSearchType(e.target.value);
                    setSearchList(0);
                    document.getElementById("findByStatus").value = "0";
                  }}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#343a40",
                    color: "#fff",
                    border: "1px solid #fff",
                    // marginLeft: "10px",
                    height: "40px",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  <option value="0">Select Search by</option>
                  <option value="name">Name</option>
                  <option value="phone">Phone</option>
                  <option value="_id">TxnId</option>
                </select>
                <input
                  type="text"
                  placeholder="Search Here"
                  className=" col-sm-4 bg-light text-dark commoncss-custom-class-width"
                  onChange={searchHandler}
                  value={searchList == 0 ? "" : searchList}
                  style={{
                    fontSize: "22px !important",
                    borderRadius: "5px",
                    border: "1px solid #fff",
                    // marginLeft: "10px",
                    height: "40px",
                    fontSize: "16px !important",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                />
                <h6 className="d-none d-sm-block" style={{ color: "#fff" }}>
                  Or
                </h6>
                <select
                  className="form-control col-sm-3 bg-light text-dark commoncss-custom-class-width"
                  id="findByStatus"
                  name="findByStatus"
                  onChange={searchByStatus}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#343a40",
                    color: "#fff",
                    border: "1px solid #fff",
                    // marginLeft: "10px",
                    height: "40px",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  <option value="0">Search Status</option>
                  <option value="FAILED">FAILED</option>
                  <option value="Pending">Pending</option>
                  {/* <option value="pending">pending</option> */}
                  <option value="PAID">PAID</option>
                  {/* <option value="pending">pending</option> */}
                </select>
                <select
                  className=" col-sm-1 bg-light text-dark commoncss-custom-class-width"
                  id="pagelimit"
                  name="pagelimit"
                  onChange={setpageLimit}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#343a40",
                    color: "#fff",
                    height: "40px",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>

              {user.length > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    textWrap: "wrap",
                  }}
                >
                  <div style={greenBox}>
                    Rs.{totals.paid.toFixed(2)} <br /> Paid Deposit
                  </div>
                  <div style={orangeBox}>
                    Rs.{totals.pending.toFixed(2)} <br /> Pending Deposit
                  </div>
                  <div style={redBox}>
                    Rs.{totals.failed.toFixed(2)} <br /> Failed Deposit
                  </div>
                </div>
              )}

              {user && user.length > 0 ? (
                <>
                  <div
                    className="table-responsive mt-3"
                    // style={{ border: "2px solid red" }}
                  >
                    <table
                      className="table text-light table-hover"
                      // style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr
                        // style={{ backgroundColor: "#14213d" }}
                        >
                          <th>#</th>
                          <th> ID</th>
                          {isUserNumberVisible && <th> phone</th>}
                          <th> User</th>
                          <th> R_orderId</th>
                          <th> Amount </th>
                          <th> Status </th>
                          <th> Action </th>
                          <th> Date </th>
                        </tr>
                      </thead>

                      <tbody>
                        {user &&
                          user.map((data, key) => {
                            var id = data._id.toString(),
                              ctr = 0;
                            var timestamp = id.slice(ctr, (ctr += 8));
                            var machineID = id.slice(ctr, (ctr += 6));
                            var processID = id.slice(ctr, (ctr += 4));
                            var counter = id.slice(ctr, (ctr += 6));
                            //console.log("id:", id);
                            //console.log("timestamp:", parseInt(timestamp, 16));
                            //console.log("machineID:", parseInt(machineID, 16));
                            //console.log("processID:", parseInt(processID, 16));
                            var counterid = parseInt(timestamp, 16);
                            let currentTime = Date.now();
                            let gameCreatedAt = new Date(
                              data.createdAt
                            ).getTime();
                            return (
                              <tr
                                key={data._id}
                                style={{ borderBottom: "1px solid #e5e7eb" }}
                              >
                                <td>
                                  {key +
                                    1 +
                                    (pageNumber ? pageNumber - 1 : 0) *
                                      limit}{" "}
                                  | JP-
                                  {counterid}
                                </td>
                                <td>{data._id}</td>
                                {isUserNumberVisible && (
                                  <td>
                                    {data.userId ? data.userId.phone : ""}
                                  </td>
                                )}
                                <td>
                                  {data.userId && (
                                    <Link
                                      className="nameButton badge"
                                      to={`/user/view_user/${data.userId._id}`}
                                      // style={{
                                      //   backgroundColor: "#e5e7eb",
                                      //   color: "#1f2937",
                                      //   fontWeight: "bold",
                                      //   borderRadius: "20px",
                                      //   padding: "5px 15px",
                                      //   textTransform: "capitalize",
                                      //   fontSize: "14px",
                                      // }}
                                    >
                                      {data.userId.name}
                                    </Link>
                                  )}
                                </td>

                                <td>
                                  {data.orderToken}
                                  <p>
                                    client ip:{" "}
                                    {data.clientIp ? data.clientIp : ""}{" "}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.status === "PAID" ||
                                      data.status === "success" ||
                                      data.status === "Credited" ||
                                      data.status === "Deposit Credited"
                                        ? "#3ea113"
                                        : data.status === "Pending"
                                        ? "#ff8903"
                                        : "#de1010",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ₹{data.amount}
                                </td>
                                <td
                                  style={{
                                    color:
                                      data.status === "PAID" ||
                                      data.status === "success" ||
                                      data.status === "Credited" ||
                                      data.status === "Deposit Credited"
                                        ? "#3ea113"
                                        : data.status === "Pending"
                                        ? "#ff8903"
                                        : "#de1010",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.status}
                                </td>
                                <td>
                                  {
                                    //&& (parseInt(gameCreatedAt) + 7200000) < currentTime
                                    data.status !== "PAID" &&
                                    data.status !== "FAILED" ? (
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          universalCheckDeposit(
                                            data.paymentGatway,
                                            data.orderId,
                                            data.orderToken,
                                            newdateFormat(data.createdAt)
                                          )
                                        }
                                      >
                                        <FaCheckDouble />
                                        {data.paymentGateway}
                                      </button>
                                    ) : (
                                      "Checked All"
                                    )
                                  }

                                  {
                                    //   <button className="ml-1 btn btn-sm btn-warning" onClick={() => withdrowPass(data._id)}>Success</button>
                                  }
                                </td>
                                <td>{dateFormat(data.createdAt)} </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
          {numberOfPages > 1 && (
            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                pageLinkStyle={{ color: "#fff" }}
                activeLinkStyle={{
                  backgroundColor: "#00ff00",
                  borderColor: "#00ff00",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Transactionhistory;
