// import axios from 'axios'
// import React, { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'

// export default function Profile() {

//     const history = useHistory()

//     const [name, setName] = useState()
//     const [phone, setPhone] = useState()
//     // const [user, setProfile] = useState()
//     const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
//     const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
//     const nodeMode = process.env.NODE_ENV;
//     if (nodeMode === "development") {
//       var baseUrl = beckendLocalApiUrl;
//     } else {
//       baseUrl = beckendLiveApiUrl;
//     }

//     const addAdmin = async (e) => {

//         e.preventDefault();

//         const access_token = localStorage.getItem("token")

//         const headers = {
//             Authorization: `Bearer ${access_token}`
//         }

//         const data = await axios.patch(baseUrl+"user/edit", {
//             name,
//             phone
//         },
//             { headers }).then((res) => {

//                 history.push("/dashboard")
//             })

//     }

//     return (
//         <div>
//             <h4 className='text-uppercase font-weight-bold my-3'>Update Admin Profile</h4>

//             <form id="add_admin_form" onSubmit={(e) => addAdmin(e)}>
//                 <div className="form-row">
//                     <div className="form-group col-md-4">
//                         <label htmlFor="passowrd">name</label>
//                         <input type="name" className="form-control" id="name" name="password" placeholder="name" onChange={(e) => {
//                             setName(e.target.value)
//                         }} required value={name && name} />
//                     </div>
//                 </div>
//                 <div className="form-row">
//                     <div className="form-group col-md-4">
//                         <label htmlFor="passowrd">Mobile</label>
//                         <input type="tel" className="form-control" id="phone" name="password" placeholder="phone" onChange={(e) => {
//                             setPhone(e.target.value)
//                         }} required value={phone && phone} />
//                     </div>

//                 </div>

//                 <button type="submit" className='btn btn-success'>submit</button>

//             </form>

//         </div>
//     )
// }

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { LuKeyRound } from "react-icons/lu";
import "./Profile.css";
import myImg from "../../../assets/images/logo.jpg";

export default function Profile() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState(null);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const addAdmin = async (e) => {
    e.preventDefault();

    const access_token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axios
      .patch(`${baseUrl}user/edit`, { name, phone }, { headers })
      .then((res) => {
        history.push("/dashboard");
      });
  };

  return (
    <>
      <h4 className="text-capitalize font-weight-bold my-3 text-dark">
        Update Admin Profile
      </h4>
      <div className="update">
        <Link
          to={"/admin/update"}
          type="button"
          className="badge badge-pill badge-info"
        >
          <LuKeyRound /> Update Password ➡
        </Link>
      </div>
      <div className="profile-container">
        <div className="profile-card">
          <div
            className="image-section"
            style={{
              padding: "30px 0px",
            }}
          >
            <img
              // src={image || myImg}
              src={process.env.PUBLIC_URL + "/new_logo/new_logo_small.png"}
              alt="Admin"
              // className="profile-image"
            />
            {/* <label htmlFor="file-upload" className="custom-file-upload">
            <i className="fas fa-upload"></i> Upload Image
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          /> */}
          </div>

          <form id="update_admin_form" onSubmit={addAdmin}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Admin"
                onChange={(e) => setName(e.target.value)}
                required
                value={name}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Mobile</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                placeholder="Enter mobile number"
                onChange={(e) => setPhone(e.target.value)}
                required
                value={phone}
              />
            </div>

            <button type="submit" className="button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
