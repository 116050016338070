import React, { useEffect, useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";
import ReactSwitch from "react-switch";

export const Sitesettings = () => {
  const [webTitle, setWebTitle] = useState("");

  const [referPercentage, setreferPercentage] = useState("");
  const [buttonColor, setButtonColor] = useState("#34445e");
  const [adminPercentage50To400, setadminPercentage50To400] = useState("");
  const [adminPercentage400To, setadminPercentage400To] = useState("");

  // new added ( )
  const [withdrawChargeTill1000, setWithdrawChargeTill1000] = useState(0);
  const [minimumWithDrawLimit, setMinimumWithdrawLimit] = useState(0);
  // new added (resultImageUpload)
  const [resultImageUpload, setResultImageUpload] = useState(false);
  // new added (autoKyc)
  const [autoKyc, setAutoKyc] = useState(false);

  const [manualUpi, setmanualUPI] = useState("");

  const [isPaymentMethod, issetPaymentMethod] = useState(true);
  const [isAutoApproveConflictBattle, setAutoApproveConflictBattle] =
    useState();

  const [whatsappNumber1, setWhatsappNumber1] = useState("");
  const [whatsappNumber2, setWhatsappNumber2] = useState("");

  const [whatsappNumber1ActiveInactive, setWhatsappNumber1ActiveInactive] =
    useState(false);
  const [whatsappNumber2ActiveInactive, setWhatsappNumber2ActiveInactive] =
    useState(false);

  const [websiteName, setWebName] = useState("");
  const [msg, setMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const [RoomCodeUrl, setRoomCodeUrl] = useState("");
  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");

  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");

  const [isLandingImage1, issetLandingImage1] = useState(true);
  const [isLandingImage2, issetLandingImage2] = useState(true);
  const [isLandingImage3, issetLandingImage3] = useState(true);
  const [isLandingImage4, issetLandingImage4] = useState(true);
  const [checkedRoom, setCheckedRoom] = useState(true);
  const [roomcodeApiBypass, setRoomcodeApiByPass] = useState(false);
  const [checkedAutoPayout, setCheckedAutoPayout] = useState(false);

  const [version, setVersion] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  function fetchSiteSettingData() {
    axios.get(baseUrl + "settings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setWebTitle(res.data.webTitle);

      setreferPercentage(res.data.referPercentage);
      setadminPercentage50To400(res.data.adminPercentage50To400);
      setadminPercentage400To(res.data.adminPercentage400To);

      setmanualUPI(res.data.manualUpi);
      issetPaymentMethod(res.data.isPaymentMethod);
      setAutoApproveConflictBattle(res.data.autoApproveConflictBattle);
      console.log(res.data.autoApproveConflictBattle);

      setCheckedRoom(res.data.roomcodeAuto);
      setCheckedAutoPayout(res.data.autoPayoutEnabled || false);
      setWebName(res.data.websiteName);

      setWhatsappNumber1(res.data.whatsapp[0].number);
      setWhatsappNumber2(res.data.whatsapp[1].number);
      setWhatsappNumber1ActiveInactive(res.data.whatsapp[0].active);
      setWhatsappNumber2ActiveInactive(res.data.whatsapp[1].active);

      setMsg(res.data.msg);
      setCompanyName(res.data.companyName);
      setCompanyAddress(res.data.companyAddress);
      setCompanyMobile(res.data.companyMobile);
      setCompanyEmail(res.data.companyEmail);
      setCompanyWebsite(res.data.companyWebsite);
      setRoomCode(res.data.roomCodeUrl);
      setRoomCodeUrl(res.data.roomCodeUrlPopular);
      setLogo(res.data.logo);
      setSmallLogo(res.data.smallLogo);
      setLandingImage1(res.data.landingImage1);
      setLandingImage2(res.data.landingImage2);
      setLandingImage3(res.data.landingImage3);
      setLandingImage4(res.data.landingImage4);
      issetLandingImage1(res.data.isLandingImage1);
      issetLandingImage2(res.data.isLandingImage2);
      issetLandingImage3(res.data.isLandingImage3);
      issetLandingImage4(res.data.isLandingImage4);
      setVersion(res.data.version);
      // new added withdraw charge
      setWithdrawChargeTill1000(res.data.withdrawChargeTill1000);

      setMinimumWithdrawLimit(res.data.minimumWithdrawLimit);
      // new added roomcodeApi bypass
      setRoomcodeApiByPass(res.data.roomcodeApiBypass);
      // new added resultImageUpload
      setResultImageUpload(res.data.resultImageUpload);
      // new added
      setAutoKyc(res.data.autoKyc);
    });
  }

  useEffect(() => {
    fetchSiteSettingData();
  }, []);

  let whatsapp = [
    {
      number: whatsappNumber1,
      active: whatsappNumber1ActiveInactive,
    },
    {
      number: whatsappNumber2,
      active: whatsappNumber2ActiveInactive,
    },
  ];

  console.log(isAutoApproveConflictBattle);

  const handleSubmit = async (e) => {
    console.log(whatsapp);
    e.preventDefault();
    let formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("webTitle", webTitle);
    formData.append("referPercentage", referPercentage);
    formData.append("adminPercentage50To400", adminPercentage50To400);
    formData.append("adminPercentage400To", adminPercentage400To);
    formData.append("manualUpi", manualUpi);
    formData.append("isPaymentMethod", isPaymentMethod);
    formData.append("autoApproveConflictBattle", isAutoApproveConflictBattle);
    formData.append("websiteName", websiteName);
    formData.append("whatsapp", JSON.stringify(whatsapp));
    formData.append("msg", msg);
    // new added withdraw charges
    formData.append("withdrawChargeTill1000", withdrawChargeTill1000);
    formData.append("minimumWithdrawLimit", minimumWithDrawLimit);
    // new added winning image
    formData.append("resultImageUpload", resultImageUpload);
    // new added for auto kyc
    formData.append("autoKyc", autoKyc);
    formData.append("companyName", companyName);
    formData.append("companyAddress", companyAddress);
    formData.append("companyMobile", companyMobile);
    formData.append("companyEmail", companyEmail);
    formData.append("companyWebsite", companyWebsite);
    formData.append("roomCodeUrl", roomCode);
    formData.append("roomCodeUrlPopular", RoomCodeUrl);
    formData.append("roomCodeAuto", checkedRoom);
    // roomcode api bypass data added
    formData.append("roomcodeApiBypass", roomcodeApiBypass);
    formData.append("autoPayoutEnabled", checkedAutoPayout);
    formData.append("logo", Logo);
    formData.append("smallLogo", SmallLogo);
    formData.append("landingImage1", LandingImage1);
    formData.append("landingImage2", LandingImage2);
    formData.append("landingImage3", LandingImage3);
    formData.append("landingImage4", LandingImage4);
    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    formData.append("version", version);
    const response = await axios.post(baseUrl + `settings`, formData);
    console.log(response.data);
    if (response.data.status === "success") {
      fetchSiteSettingData();
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");

    Logo1.onchange = (e) => {
      const [file] = Logo1.files;
      setLogo(file);
    };
    Logo2.onchange = (e) => {
      const [file] = Logo2.files;
      setSmallLogo(file);
    };
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files;
      setLandingImage1(file);
    };
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files;
      setLandingImage2(file);
    };
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files;
      setLandingImage3(file);
    };
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files;
      setLandingImage4(file);
    };
  }, []);

  const handleChangeRoom = (val) => {
    setCheckedRoom(val);
  };

  // new added to bypass room code api
  const handleRoomcodeApiBypass = (val) => {
    setRoomcodeApiByPass(val);
  };
  // new added to resultImageUpload
  const handleResultImageUpload = (val) => {
    setResultImageUpload(val);
  };
  // new added for autoKyc
  const handleAutoKyc = (val) => {
    setAutoKyc(val);
  };

  const handleChangePayout = (val) => {
    setCheckedAutoPayout(val);
  };

  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin" style={{ padding: "0px" }}>
          <div className="card">
            <div
              className="card-body "
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4 className="card-title"> Website Setting</h4>
              <form
                onSubmit={handleSubmit}
                method="patch"
                encType="multipart/form-data"
                // style={{
                //   backgroundColor: "#0e1b2b",
                //   borderRadius: "10px",
                //   padding: "20px",
                //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                //   // maxWidth: "1100px",
                //   margin: "auto",
                //   color: "#f0f0f0",
                // }}
              >
                {/* <div
                  style={{
                    border: "1px solid gray",
                    height: "1px",
                    width: "100%",
                  }}
                >
                  {" "}
                </div> */}
                <h4
                  className="card-subtitle"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#fff",
                    // textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  UI Settings
                </h4>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Website Title</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={webTitle}
                      onChange={(e) => setWebTitle(e.target.value)}
                      placeholder="Enter Website Title"
                    />
                  </div>
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Website Name</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={websiteName}
                      onChange={(e) => setWebName(e.target.value)}
                      placeholder="Enter Website Name"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Whatsapp Number 1
                    </label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        style={{
                          flex: "1",
                          height: "40px",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={whatsappNumber1}
                        onChange={(e) => setWhatsappNumber1(e.target.value)}
                        placeholder="Enter Whatsapp Number 1"
                      />
                      <select
                        style={{
                          height: "40px",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={whatsappNumber1ActiveInactive}
                        onChange={(e) =>
                          setWhatsappNumber1ActiveInactive(e.target.value)
                        }
                      >
                        <option value="true">Active</option>
                        <option value="false">Off</option>
                      </select>
                    </div>
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Whatsapp Number 2
                    </label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        style={{
                          flex: "1",
                          height: "40px",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        type="text"
                        value={whatsappNumber2}
                        onChange={(e) => setWhatsappNumber2(e.target.value)}
                        placeholder="Enter Whatsapp Number 2"
                      />
                      <select
                        style={{
                          height: "40px",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                        value={whatsappNumber2ActiveInactive}
                        onChange={(e) =>
                          setWhatsappNumber2ActiveInactive(e.target.value)
                        }
                      >
                        <option value="true">Active</option>
                        <option value="false">Off</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Payment Method</label>

                    <select
                      style={{
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      value={isPaymentMethod}
                      onChange={(e) => issetPaymentMethod(e.target.value)}
                    >
                      <option value="phonepe">Phonepe</option>
                      <option value="upigateway">UPI Gateway</option>
                      <option value="manual">Manual Payment</option>
                      <option value="both">All Payment Mode</option>
                    </select>
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Manual Payment UPI
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={manualUpi}
                      onChange={(e) => setmanualUPI(e.target.value)}
                      placeholder="Enter Manual Payment UPI"
                    />
                  </div>
                </div>

                <div style={{ flex: "1", minWidth: "300px" }}>
                  <label style={{ fontSize: "18px", marginTop: "15px" }}>
                    Auto Approve Conflict Battle
                  </label>
                  <select
                    style={{
                      width: "100%",
                      height: "40px",
                      marginTop: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                    value={isAutoApproveConflictBattle}
                    onChange={(e) =>
                      setAutoApproveConflictBattle(e.target.value)
                    }
                  >
                    <option value="false">Off</option>
                    <option value="true">Active</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      User Refer Percentage
                    </label>

                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={referPercentage}
                      onChange={(e) => setreferPercentage(e.target.value)}
                      placeholder="Enter Refer Percentage"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Commission (50 to 400)
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={adminPercentage50To400}
                      onChange={(e) =>
                        setadminPercentage50To400(e.target.value)
                      }
                      placeholder="Enter Commission Greater Than 50 to 400"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Commission (Greater than 400)
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        textAlign: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={adminPercentage400To}
                      onChange={(e) => setadminPercentage400To(e.target.value)}
                      placeholder="Enter Commission Greater Than 400"
                    />
                  </div>
                </div>

                {/* new added withdraw charges */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Withdraw Charge in &#x20B9; (till 1000)
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        textAlign: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={withdrawChargeTill1000}
                      onChange={(e) =>
                        setWithdrawChargeTill1000(e.target.value)
                      }
                      placeholder="Enter withdraw Charge in rupees  (till 1000 withdraw )"
                    />
                  </div>
                </div>

                {/* new added minimum withdraw limit */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Minimum Withdraw Limit
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        textAlign: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={minimumWithDrawLimit}
                      onChange={(e) => setMinimumWithdrawLimit(e.target.value)}
                      placeholder="Enter minimum user withdraw limit"
                    />
                  </div>
                </div>

                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#fff",
                    // textAlign: "center",
                    margin: "20px 0",
                  }}
                >
                  Company Details Settings
                </h4>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Commpany Name</label>
                    <input
                      style={{
                        textIndent: "5px",
                        textAlign: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      placeholder="Enter Company Name"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Commpany Address</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      placeholder="Enter Company Address"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Commpany Mobile</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={companyMobile}
                      onChange={(e) => setCompanyMobile(e.target.value)}
                      placeholder="Enter Company Mobile"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Commpany Email</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      placeholder="Enter Company Email"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Commpany Website</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={companyWebsite}
                      onChange={(e) => setCompanyWebsite(e.target.value)}
                      placeholder="Enter Company Website"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Right Logo</label>
                    <input
                      style={{
                        flex: "1",
                        textIndent: "5px",
                        width: "100%",
                        padding: "5px 5px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="file"
                      name="Logo"
                      id="Logo"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Left Logo</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        padding: "5px 5px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="file"
                      name="SmallLogo"
                      id="SmallLogo"
                    />
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Message</label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                      placeholder="Write Your Message Here"
                    />
                  </div>
                </div>

                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#fff",
                    // textAlign: "center",
                    margin: "20px 0",
                  }}
                >
                  Game Settings
                </h4>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "20px",
                    alignItems: "center",
                  }}
                >
                  {/* First Portion */}
                  <div
                    style={{
                      flex: "1",
                    }}
                  >
                    {[1, 2].map((num) => (
                      <div
                        key={num}
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <label style={{ fontSize: "18px" }}>
                          Game Image {num}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            gap: "5px",
                            marginTop: "5px",
                          }}
                        >
                          <input
                            style={{
                              textIndent: "5px",
                              width: "100%",
                              padding: "5px 5px",
                              marginTop: "5px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              margin: "auto 0",
                            }}
                            type="file"
                            name={`LandingImage${num}`}
                            id={`LandingImage${num}`}
                          />
                          <select
                            style={{
                              height: "40px",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                            value={eval(`isLandingImage${num}`)}
                            onChange={(e) =>
                              eval(`setLandingImage${num}`)(e.target.value)
                            }
                          >
                            <option value="true">On</option>
                            <option value="false">Off</option>
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Second Portion */}
                  <div style={{ flex: "1" }}>
                    {[3, 4].map((num) => (
                      <div key={num} style={{ marginBottom: "20px" }}>
                        <label style={{ fontSize: "18px" }}>
                          Game Image {num}
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginTop: "5px",
                          }}
                        >
                          <input
                            style={{
                              // flex: "1",
                              textIndent: "5px",
                              width: "100%",
                              padding: "5px 5px",
                              marginTop: "5px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              margin: "auto 0",
                            }}
                            type="file"
                            name={`LandingImage${num}`}
                            id={`LandingImage${num}`}
                          />
                          <select
                            style={{
                              height: "40px",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                            value={eval(`isLandingImage${num}`)}
                            onChange={(e) =>
                              eval(`setLandingImage${num}`)(e.target.value)
                            }
                          >
                            <option value="true">On</option>
                            <option value="false">Off</option>
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>Version</label>

                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="text"
                      value={version}
                      onChange={(e) => setVersion(e.target.value)}
                      placeholder="Enter Version"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      // border: "2px solid green",
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      style={{
                        flex: "1",
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      <label
                        htmlFor="autoroomcode"
                        style={{ display: "block", fontSize: "18px" }}
                      >
                        Auto Roomcode
                      </label>
                      <ReactSwitch
                        checked={checkedRoom}
                        onChange={handleChangeRoom}
                        style={{ display: "none" }}
                      />
                    </div>
                    {/* room code bypass button */}
                    <div
                      style={{
                        flex: "1",
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      <label
                        htmlFor="autoroomcode"
                        style={{ display: "block", fontSize: "18px" }}
                      >
                        Roomcode Bypass
                      </label>
                      <ReactSwitch
                        checked={roomcodeApiBypass}
                        onChange={handleRoomcodeApiBypass}
                        style={{ display: "none" }}
                      />
                    </div>
                    {/* add new to control for result image upload */}
                    <div
                      style={{
                        flex: "1",
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      <label
                        htmlFor="resultImageUpload"
                        style={{ display: "block", fontSize: "18px" }}
                      >
                        Winning Image
                      </label>
                      <ReactSwitch
                        checked={resultImageUpload}
                        onChange={handleResultImageUpload}
                        style={{ display: "none" }}
                      />
                    </div>

                    {/* autopayout button */}
                    <div
                      style={{
                        flex: "1",
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      <label
                        htmlFor="autoroomcode"
                        style={{ display: "block", fontSize: "18px" }}
                      >
                        Auto Payout
                      </label>
                      <ReactSwitch
                        checked={checkedAutoPayout}
                        onChange={handleChangePayout}
                        style={{ display: "none" }}
                      />
                    </div>

                    {/* autokyc button */}
                    <div
                      style={{
                        flex: "1",
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      <label
                        htmlFor="autoKyc"
                        style={{ display: "block", fontSize: "18px" }}
                      >
                        Auto Kyc
                      </label>
                      <ReactSwitch
                        checked={autoKyc}
                        onChange={handleAutoKyc}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>

                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      Classic Room Code Url
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="url"
                      value={roomCode}
                      onChange={(e) => setRoomCode(e.target.value)}
                      placeholder="Enter Classic RoomCode Url"
                    />
                  </div>
                  <div style={{ flex: "1", minWidth: "300px" }}>
                    <label style={{ fontSize: "18px" }}>
                      {" "}
                      Popular Room Code Url{" "}
                    </label>
                    <input
                      style={{
                        textIndent: "5px",
                        width: "100%",
                        height: "40px",
                        marginTop: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                      type="url"
                      value={RoomCodeUrl}
                      onChange={(e) => setRoomCodeUrl(e.target.value)}
                      placeholder="Enter Popular RoomCode Url"
                    />
                  </div>
                </div>

                <div style={{ marginTop: "25px", textAlign: "center" }}>
                  <button
                    type="submit"
                    className="btn btn-success"
                    style={{
                      // width: "100%",
                      height: "40px",
                      // padding: "10px 20px",
                      backgroundColor: buttonColor,
                      color: "#fff",
                      borderRadius: "5px",
                      // cursor: "pointer",
                      fontSize: "18px",
                      border: "none",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={() => setButtonColor("darkgreen")}
                    onMouseLeave={() => setButtonColor("#34445e")}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Gateway />
    </>
  );
};
