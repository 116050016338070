import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const SucWithdraw = ({ isUserNumberVisible }) => {
  const [user, setUser] = useState();

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  let [limit, setLimit] = useState(10);
  const setpageLimit = (event) => {
    setLimit(event.target.value);
    setPageNumber(0);
  };
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [startDate, setStartDate] = useState(
    new DateObject().subtract(4, "days")
  );
  const [endDate, setEndDate] = useState(new DateObject().add(4, "days"));
  // const [values, setValues] = useState([new DateObject().subtract(4, "days"), new DateObject().add(4, "days")]);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    axios
      .get(
        `${baseUrl}txn/withdrawalreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,
        { headers }
      )
      .then((res) => {
        setUser(res.data.datefind);
        // console.log("withdraw data ", res.data.datefind);

        setNumberOfPages(res.data.totalPages);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, startDate, endDate]);

  if (!user) return null;

  const createDownloadData = () => handleExport();
  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Withdrawal Amount",
        E: "Status",
        F: "Upi Id",
        G: "Action by",
      },
    ];

    user.forEach((row) => {
      table1.push({
        A: row._id,
        B: row.userId ? row.userId.name : "",
        C:
          row.userId && isUserNumberVisible
            ? row.userId.phone
            : "+91XXXXXXXXXX",
        D: row.amount ? row.amount : "",
        E: row.status ? row.status : "",
        F: row.userId.upiId,
        G: row.actionBy ? row.actionBy.name : "N/A",
      });
    });

    const ws = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "WithdrawalReport.xlsx");
  };

  const downloadPDF = () => {
    // Prepare table data
    let table1 = user.map((row) => ({
      A: row._id,
      B: row.userId ? row.userId.name : "",
      C: row.userId && isUserNumberVisible ? row.userId.phone : "+91XXXXXXXXXX",
      D: row.amount ? row.amount : "",
      E: row.status ? row.status : "",
      F: row.userId.upiId,
      G: row.actionBy ? row.actionBy.name : "N/A",
    }));

    // Generate PDF
    const doc = new jsPDF();
    const columns = [
      { header: "Id", dataKey: "A" },
      { header: "UserName", dataKey: "B" },
      { header: "PhoneNumber", dataKey: "C" },
      { header: "Withdrawal Amount", dataKey: "D" },
      { header: "Status", dataKey: "E" },
      { header: "Upi Id", dataKey: "F" },
      { header: "Action by", dataKey: "G" },
    ];

    // Use autoTable to draw the table
    doc.autoTable({
      head: [columns.map((col) => col.header)], // Pass headers only
      body: table1.map((row) => [
        row.A,
        row.B,
        row.C,
        row.D,
        row.E,
        row.F,
        row.G,
      ]),
      startY: 20, // Set the starting point for the table
    });

    // Save the file
    doc.save("WithdrawalReport.pdf");
  };
  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div
          className="card"
          // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
        >
          <div
            className="card-body"
            // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
          >
            <h4
              className="card-title mt-4"
              // style={{ color: "white" }}
            >
              Withdrawal Reports
            </h4>
            {/* download buttons */}
            <div className="download-button">
              <button
                onClick={() => {
                  createDownloadData();
                }}
                className="btn btn-primary "
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#0056b3";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#007bff";
                  e.target.style.transform = "scale(1)";
                }}
              >
                <RiFileExcel2Line style={{ fontSize: "24px" }} />
              </button>

              {/* pdf download button */}
              <button
                onClick={() => {
                  // createPDF();
                  downloadPDF();
                }}
                className="btn btn-danger"
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#841f29";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#dc3545";
                  e.target.style.transform = "scale(1)";
                }}
              >
                <FaRegFilePdf style={{ fontSize: "24px" }} />
              </button>
            </div>

            <div className="d-flex flex-wrap justify-content-start justify-content-sm-between  align-items-center mt-3 mb-3 w-100">
              {/* Start Date Picker */}
              <div
                style={{ marginRight: "10px" }}
                className="commoncss-custom-with-datediv"
              >
                <label
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  Start Date:
                </label>
                <DatePicker
                  value={startDate}
                  onChange={setStartDate}
                  style={{
                    backgroundColor: "#f8f9fb",
                    color: "#black",
                    borderRadius: "5px",
                    padding: "5px",
                    minWidth: "120px",
                    width: "100%",
                    height: "40px",
                  }}
                />
              </div>

              {/* End Date Picker */}
              <div
                style={{ marginRight: "10px" }}
                className="commoncss-custom-with-datediv"
              >
                <label
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  End Date:
                </label>
                <DatePicker
                  value={endDate}
                  onChange={setEndDate}
                  style={{
                    backgroundColor: "#f8f9fb",
                    color: "#black",
                    borderRadius: "5px",
                    padding: "5px",
                    minWidth: "120px",
                    width: "100%",
                    height: "40px",
                  }}
                />
              </div>

              <select
                // className="form-control col-sm-1 bg-light text-dark"
                className="commoncss-custom-with-datediv"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#f8f9fb",
                  color: "black",
                  minWidth: "120px",
                  height: "40px",
                  marginBottom: "4px",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            {user.length > 0 ? (
              <>
                <div className="table-responsive mt-3">
                  <table
                    className="table text-light table-hover"
                    // style={{ borderRadius: "10px", color: "#fff" }}
                  >
                    <thead>
                      <tr
                      // style={{ backgroundColor: "s#14213d" }}
                      >
                        <th>#</th>
                        <th>ID</th>
                        {isUserNumberVisible && <th>Phone</th>}
                        <th>User</th>
                        <th>Gateway</th>
                        <th>Paytm Number</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Time</th>
                        <th>Action By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.map((data, key) => (
                        <tr
                          key={data._id}
                          style={{ borderBottom: "1px solid #e5e7eb" }}
                        >
                          <td>
                            {key +
                              1 +
                              (pageNumber ? pageNumber - 1 : 0) * limit}
                          </td>
                          <td>{data._id}</td>
                          {isUserNumberVisible && (
                            <td>{data.userId ? data.userId.phone : ""}</td>
                          )}
                          <td>
                            {data.userId && (
                              <Link
                                className="nameButton badge"
                                to={`/user/view_user/${data.userId._id}`}
                                // style={{
                                //   backgroundColor: "#e5e7eb",
                                //   color: "#1f2937",
                                //   fontWeight: "bold",
                                //   borderRadius: "20px",
                                //   padding: "5px 15px",
                                //   textTransform: "capitalize",
                                //   fontSize: "14px",
                                // }}
                              >
                                {data.userId.name}
                              </Link>
                            )}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data.withdrawType}
                          </td>
                          <td
                            style={{
                              color: data.userId.upiId ? "#3e10e3" : "",
                            }}
                          >
                            {data.userId.upiId ?? "None"}
                          </td>
                          <td
                            style={{
                              color:
                                data.status === "SUCCESS" ||
                                data.status === "Refunded" ||
                                data.status === "CREDITBACK"
                                  ? "#3ea113"
                                  : data.status === "Pending"
                                  ? "#aaad11"
                                  : "#ff0000",
                              fontWeight: "bold",
                            }}
                          >
                            ₹{data.amount}
                            {data.withdrawCharge > 0 && (
                              <div className="text-light fw-normal small">
                                Withdraw charge: &#x20B9; {data.withdrawCharge}
                              </div>
                            )}
                          </td>

                          <td
                            style={{
                              color:
                                data.status === "FAILED" ||
                                data.status === "reject"
                                  ? "#ff0000"
                                  : data.status === "Pending"
                                  ? "#aaad11"
                                  : "#3ea113",
                              fontWeight: "bold",
                            }}
                          >
                            {data.status === "Refunded"
                              ? "Withdraw Credited Back to Wallet"
                              : data.status === "none"
                              ? "Processing"
                              : data.status}
                          </td>
                          <td>{dateFormat(data.createdAt)}</td>
                          <td
                            style={{
                              color: data.actionBy ? "#00ff00" : "yellow",
                            }}
                          >
                            {data.actionBy ? data.actionBy.name : "None"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#14213d",
                  padding: "20px",
                  borderRadius: "10px",
                  color: "#fff",
                  marginTop: "15px",
                }}
              >
                <h4>No Data Found</h4>
              </div>
            )}
          </div>
        </div>
        {numberOfPages > 1 && (
          <div className="mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              pageLinkStyle={{ color: "#fff" }}
              activeLinkStyle={{
                backgroundColor: "#00ff00",
                borderColor: "#00ff00",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SucWithdraw;
