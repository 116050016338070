import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";

const ImageSlider = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  // state variables for title and url
  const [uploadTitle, setUploadTitle] = useState("");
  const [clickUrl, setClickUrl] = useState("");
  // State variables for file, preview, and error message
  const [image, setImage] = useState([]);
  const [preview, setPreview] = useState([]);
  const [error, setError] = useState("");
  const [imagesFromApi, setImagesFromApi] = useState([]);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Create a URL for the image preview
      const imgURL = URL.createObjectURL(file);

      // Check image dimensions before setting state
      const img = new Image();
      img.src = imgURL;
      img.onload = () => {
        // if (img.width <= 1020 && img.height <= 720) {
        if (
          img.width <= 464 &&
          img.width >= 460 &&
          img.height <= 118 &&
          img.height >= 115
        ) {
          // if (true) {
          // uncomment for multiple images at a time
          // setImage((prev) => [...prev, file]);
          // setPreview((prev) => [...prev, imgURL]);

          // single image at a time
          setImage([file]);
          setPreview([imgURL]);
          setError(""); // Clear error if valid
        } else {
          setError(
            "Image must be in between (width*height)  464*118px to 460*115px"
          );
          setImage([]); // Clear previous image if invalid
          setPreview([]);
        }
      };
    }
  };

  // onDelete handler
  const imageDeleteHandlerPreview = (index) => {
    setImage((prev) => prev.filter((val, i) => i !== index));
    setPreview((prev) => prev.filter((val, i) => i !== index));
    // setImage([]);
    // setPreview([]);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!image.length) {
      setError("Please select a valid image");
      return;
    }

    const formData = new FormData();
    // formData.append("image", image);
    // to append multiple images
    formData.append("uploadTitle", uploadTitle);
    formData.append("clickUrl", clickUrl);
    image.forEach((imageFile) => {
      formData.append("files", imageFile);
    });

    try {
      console.log("Form data is", formData);
      console.log("Data at use state is:-", image);
      // Replace with your backend API endpoint

      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const data = await axios.post(baseUrl + `slideimg-upload`, formData, {
        headers,
      });

      console.log("Image uploaded successfully:", data);
      setPreview([]);
      setImage([]);
      setUploadTitle("");
      setClickUrl("");
      getSliderImgFromApi();
      alert("Uploaded successfully");
      // Handle success here (e.g., clear the form, show success message)
    } catch (err) {
      console.error("Error uploading image:", err);
    }
  };

  const getSliderImgFromApi = async () => {
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const data = await axios.get(baseUrl + `slideimg-get`, { headers });
      console.log("Data from slider images are ", data);
      setImagesFromApi(data.data[0].images);
    } catch (error) {
      console.log(error);
    }
  };

  // delete images from database
  const deleteImageFromDB = async (id) => {
    console.log("Image delete id is", id);
    try {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const data = await axios.delete(baseUrl + `slideimg-delete/${id}`, {
        headers,
      });
      console.log("Data from slider images are ", data);
      getSliderImgFromApi();
      alert("Deleted Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSliderImgFromApi();
  }, []);

  return (
    <div className="border rounded p-3 border-dark d-flex flex-column w-100 image-uploader">
      <form onSubmit={handleSubmit} className="w-100">
        <div className="upload-box w-100 text-dark p-1 text-center mb-4">
          {/* title and url */}
          <div className="w-100 d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center  mb-4">
            {/* title input */}
            <div className="mr-4 d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center ">
              <label className="mr-2 text-dark fw-bold fs-2 ">Title</label>
              <input
                type="text"
                placeholder="Enter title of upload"
                value={uploadTitle}
                onChange={(e) => setUploadTitle(e.target.value)}
              />
            </div>
            {/* url input */}
            <div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center mr-4">
              <label className="mr-2 text-dark fw-bold fs-2">Click Url</label>
              <input
                type="text"
                placeholder="Enter click url"
                value={clickUrl}
                onChange={(e) => setClickUrl(e.target.value)}
              />
            </div>
          </div>
          <label
            htmlFor="file-input"
            className="upload-label  cursor-pointer"
            style={{
              fontSize: "18px",
              color: "#023e8a",
              fontWeight: "bold",
            }}
          >
            Click To Upload
          </label>
          <input
            id="file-input"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            hidden
          />
        </div>

        {preview && (
          <div className="image-preview p-1 d-flex m-4">
            {preview?.map((val, index) => {
              return (
                <div
                  key={index}
                  className="border ml-1 mx-auto  w-auto border-dark rounded position-relative"
                >
                  <div
                    className=" position-absolute top-2 left-2 cursor-pointer text-danger fs-1"
                    onClick={() => imageDeleteHandlerPreview(index)}
                  >
                    <MdDelete />
                  </div>
                  <img src={val} alt="Preview" width="200" />
                </div>
              );
            })}
          </div>
        )}

        {error && (
          <div className="error-message text-center p-2 text-danger">
            {error}
          </div>
        )}

        <div className="w-100 text-center">
          <button
            type="submit"
            className="btn btn-primary cursor-pointer "
            disabled={!image.length}
          >
            Upload
          </button>
        </div>
      </form>
      {/* images from api's are */}
      {imagesFromApi && (
        <div className="image-preview p-1 d-flex mt-4 flex-wrap justify-content-center">
          {imagesFromApi?.map((val, index) => {
            // console.log("ttittttttttttttttttttttttle", val);
            return (
              <div
                key={index}
                className="border ml-1  w-auto  rounded position-relative mt-1 "
              >
                <div
                  className=" position-absolute bottom-0 right-0 cursor-pointer text-danger fs-1"
                  style={{
                    position: "absolute",
                    bottom: "2px",
                    right: "2px",
                    fontSize: "20px",
                  }}
                  onClick={() => deleteImageFromDB(val._id)}
                >
                  <MdDelete />
                </div>
                {/* image title and url */}
                <div className="px-2">
                  {/* title */}
                  <div
                    style={{
                      maxWidth: "200px",
                      fontWeight: "bold",
                    }}
                    className="text-truncate"
                  >
                    Title: {val.uploadTitle}
                  </div>

                  {/* upload url */}

                  <div
                    style={{
                      maxWidth: "200px",
                    }}
                    className="text-truncate mb-2"
                  >
                    Url:{" "}
                    <a href={val.clickUrl} target="_blank">
                      {val.clickUrl}
                    </a>
                  </div>
                </div>
                <img
                  className=" mx-auto"
                  // src={`/public/${val.filename}`}
                  src={baseUrl + `public/${val.filename}`}
                  alt="Preview"
                  width="200"
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
