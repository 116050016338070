import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ReactSwitch from "react-switch";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const SignUpBonus = ({ isUserNumberVisible }) => {
  const [user, setUser] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  let [limit, setLimit] = useState(10);
  const setpageLimit = (event) => {
    setLimit(event.target.value);
    setPageNumber(0);
  };

  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [startDate, setStartDate] = useState(
    new DateObject().subtract(4, "days")
  );
  const [endDate, setEndDate] = useState(new DateObject().add(4, "days"));
  const [signupWalletBalance, setSignupWalletBalance] = useState(0);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const [bonusWalletRecharge, setBonusWalletRecharge] = useState("");
  const [isSignupBonusAllowed, setIsSignupBonusAllowed] = useState(false);

  const handleSignupBonusSetting = async (val) => {
    console.log("Signup bonus setting is:----", val);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    const confirmation = window.confirm("Are you Sure to chnage this setting");
    if (!confirmation) {
      return;
    }
    setIsSignupBonusAllowed(val);
    try {
      let response = await axios.post(
        `${baseUrl}setting/signupbonus`,
        { state: val },
        { headers }
      );
      console.log("Updatedddd.....");
      alert("Successfully Updated");
    } catch (error) {
      console.log("Error is :", error);
    }
  };

  const handleReachargeWallet = async (e) => {
    e.preventDefault();
    const confirmation = window.confirm(
      `Are you sure to want to recharge bonus wallet with ${bonusWalletRecharge} `
    );
    if (!confirmation) {
      return;
    }
    console.log("hkdjhsdkhdkasdha", bonusWalletRecharge);
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      let response = await axios.post(
        `${baseUrl}recharge/bonuswallet`,
        { amount: bonusWalletRecharge },
        { headers }
      );
      console.log("Adddedddddddddddddd");
      setBonusWalletRecharge("");
      getBonusTransactions();
      getWalletBalance();
    } catch (error) {
      console.log("error", error);
    }
  };
  //to get bonus transactions
  const getBonusTransactions = async () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      let response = await axios.get(
        `${baseUrl}signupbonus?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,
        { headers }
      );
      console.log("Response of bonus transactions is", response);
      console.log("total pages are ", response.data.data);
      setUser(response.data.data);
      setNumberOfPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const getWalletBalance = async () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      axios
        .get(baseUrl + "bonus-wallet-balance")
        .then((response) => {
          console.log(
            "wwwwwwwwwwwwwwwwwwwwwww",
            response.data.data.bonusWallet
          );
          setSignupWalletBalance(response.data.data.bonusWallet);
        })
        .catch((error) => {
          console.error("Error fetching settings:", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getWalletBalance();
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // add new to get website setting for minimum withdraw
    axios
      .get(baseUrl + "settings/data")
      .then((response) => {
        console.log("Setting dataat is ssssssssssssss", response.data);
        console.log(
          "Setting dataat is ssssssssssssss",
          response.data.isSignupBonusAllowed
        );
        setIsSignupBonusAllowed(response.data.isSignupBonusAllowed);
        // let mini_withdraw = +response.data.;
        // setMinimumWithdrawLimit(mini_withdraw);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
    // end
  }, []);

  useEffect(() => {
    getBonusTransactions();
  }, [pageNumber, limit, startDate, endDate]);

  if (!user) return null;

  const createDownloadData = () => handleExport();
  const handleExport = () => {
    let table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Bonus Amount",
        E: "Closing Bal",
        F: "Req Type",
      },
    ];

    user.forEach((row) => {
      table1.push({
        A: row._id,
        B: row.bonusAddedBy ? row.bonusAddedBy?.name : row.bonusGivenTo?.name,
        C: isUserNumberVisible
          ? String(
              row.bonusAddedBy
                ? row.bonusAddedBy?.phone
                : row.bonusGivenTo?.phone
            )
          : "+91XXXXXXXXXX",
        D: row.amount ? String(row.amount) : "",
        E: row.bonusClosingBalance ? row.bonusClosingBalance : "N/A",
        F: row.reqType ? row.reqType : "N/A",
      });
    });

    const ws = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "BonusReport");
    XLSX.writeFile(wb, "BonusReport.xlsx");
  };

  const downloadPDF = () => {
    // Prepare table data
    let table1 = user?.map((row) => ({
      A: row._id || "",
      B: row.bonusAddedBy ? row.bonusAddedBy?.name : row.bonusGivenTo?.name,
      C: isUserNumberVisible
        ? String(
            row.bonusAddedBy ? row.bonusAddedBy?.phone : row.bonusGivenTo?.phone
          )
        : "+91XXXXXXXXXX",
      D: row.amount ? String(row.amount) : "",
      E: row.bonusClosingBalance ? row.bonusClosingBalance : "N/A",
      F: row.reqType ? row.reqType : "N/A",
    }));

    // Generate PDF
    const doc = new jsPDF();
    const columns = [
      { header: "Id", dataKey: "A" },
      { header: "UserName", dataKey: "B" },
      { header: "PhoneNumber", dataKey: "C" },
      { header: "Bonus Amount", dataKey: "D" },
      { header: "Closing Bal", dataKey: "E" },
      { header: "Req Type", dataKey: "F" },
    ];

    // Use autoTable to draw the table
    doc.autoTable({
      head: [columns.map((col) => col.header)], // Pass only column headers
      body: table1.map((row) => [row.A, row.B, row.C, row.D, row.E, row.F]),
      startY: 20, // Set the starting point for the table
    });

    // Save the generated PDF
    doc.save("BonusReport.pdf");
  };

  return (
    <>
      <div className="row">
        <div className="col-12 grid-margin">
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body d-flex flex-column flex-md-row text-center text-light"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              {/* <h4 className="card-title">Add New Admin / Agent</h4> */}
              {/* wallet balance */}
              <div
                style={{ borderRadius: "16px" }}
                className="col-md-5 m-auto bg-black  p-0 border border-white"
              >
                <div className=" border-bottom border-white   ">
                  <h4 className=" py-2 m-0 ">Wallet balance</h4>
                  <h2 className="fw-bolder  p-0">
                    {" "}
                    ₹<span>{signupWalletBalance}</span>
                  </h2>
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(0, 0, 0)",
                    borderRadius: " 0px 0px 20px 20px",
                  }}
                  className=" item-bottom"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      minWidth: "100px",
                      textAlign: "center",
                      padding: "8px 0px",
                    }}
                  >
                    <label
                      htmlFor="autoroomcode"
                      style={{
                        display: "block",
                        fontSize: "18px",
                        marginRight: "4px",
                      }}
                    >
                      Signup Bonus
                    </label>
                    <ReactSwitch
                      checked={isSignupBonusAllowed}
                      onChange={handleSignupBonusSetting}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>

              {/* form */}
              <form
                id="add_admin_form text-white"
                action=""
                method="post"
                style={{
                  borderRadius: "16px !important",
                  border: "1px solid white",
                  gap: "10px",
                }}
                className="col-md-5 mx-auto bg-black  p-0  border-white d-flex flex-column px-2 justify-content-center mt-2 p-4 mt-md-0 "
              >
                {/* <div> */}
                {/* <div> */}
                {/* <label htmlFor="mobile">Mobile</label> */}
                <input
                  type="text"
                  id="rechargeWallet"
                  name="rechargeWallet"
                  className="rounded  border py-2"
                  placeholder="Enter Amount "
                  autocomplete="off"
                  value={bonusWalletRecharge}
                  onChange={(e) => setBonusWalletRecharge(e.target.value)}
                />
                {/* </div> */}
                {/* </div> */}

                <div>
                  <button
                    className="btn btn-primary my-1 mt-2"
                    type="submit"
                    onClick={(e) => handleReachargeWallet(e)}
                  >
                    Recharge Wallet
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* signup reports */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div
            className="card"
            // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
          >
            <div
              className="card-body"
              // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
            >
              <h4
                className="card-title mt-4"
                // style={{ color: "white" }}
              >
                Signup Bonus Data
              </h4>
              {/* download data buttons */}
              <div className="download-button">
                <button
                  onClick={() => {
                    createDownloadData();
                  }}
                  className="btn btn-primary "
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#0056b3";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#007bff";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <RiFileExcel2Line style={{ fontSize: "24px" }} />
                </button>

                {/* pdf download button */}
                <button
                  onClick={() => {
                    downloadPDF();
                  }}
                  className="btn btn-danger"
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#841f29";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#dc3545";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  <FaRegFilePdf style={{ fontSize: "24px" }} />
                </button>
              </div>

              <div className="d-flex flex-wrap justify-content-start justify-content-sm-between  align-items-center mt-3 mb-3 w-100">
                {/* Start Date Picker */}
                <div
                  // style={{ marginRight: "10px" }}
                  style={{
                    marginRight: "10px",
                    // border: "2px solid green",
                  }}
                  className="commoncss-custom-with-datediv"
                >
                  <label
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  >
                    Start Date:
                  </label>
                  <DatePicker
                    value={startDate}
                    onChange={setStartDate}
                    style={{
                      backgroundColor: "#f8f9fb",
                      color: "#black",
                      borderRadius: "5px",
                      padding: "5px",
                      minWidth: "120px",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>

                {/* End Date Picker */}
                <div
                  style={{ marginRight: "10px" }}
                  className="commoncss-custom-with-datediv"
                >
                  <label
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  >
                    End Date:
                  </label>
                  <DatePicker
                    value={endDate}
                    onChange={setEndDate}
                    style={{
                      backgroundColor: "#f8f9fb",
                      color: "#black",
                      borderRadius: "5px",
                      padding: "5px",
                      minWidth: "120px",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>

                <select
                  className="commoncss-custom-with-datediv"
                  id="pagelimit"
                  name="pagelimit"
                  onChange={setpageLimit}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: "#f8f9fb",
                    color: "black",
                    minWidth: "120px",
                    height: "40px",
                    marginBottom: "4px",
                  }}
                >
                  <option value="10">Set limit</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              {user.length > 0 ? (
                <>
                  <div className="table-responsive mt-3">
                    <table
                      className="table text-light table-hover"
                      // style={{ borderRadius: "10px", color: "#fff" }}
                    >
                      <thead>
                        <tr
                        // style={{ backgroundColor: "#14213d" }}
                        >
                          <th>#</th>
                          <th>Id</th>
                          {isUserNumberVisible && <th>Mob.No</th>}
                          <th>User</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Date</th>
                          <th>Req Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user.map((data, key) => (
                          <tr
                            key={data._id}
                            // style={{ borderBottom: "1px solid #e5e7eb" }}
                          >
                            <td>
                              {key +
                                1 +
                                (pageNumber ? pageNumber - 1 : 0) * limit}
                            </td>
                            <td>{data._id}</td>
                            {isUserNumberVisible && (
                              <td>
                                {data.bonusAddedBy
                                  ? data.bonusAddedBy.phone
                                  : data?.bonusGivenTo?.phone}
                              </td>
                            )}
                            <td>
                              {data?.bonusAddedBy ? (
                                <Link
                                  className="nameButton badge"
                                  to={`/user/view_user/${data.bonusAddedBy._id}`}
                                >
                                  {data?.bonusAddedBy?.name}
                                </Link>
                              ) : (
                                <Link
                                  className="nameButton badge"
                                  to={`/user/view_user/${data?.bonusGivenTo?._id}`}
                                >
                                  {data.bonusGivenTo?.name}
                                </Link>
                              )}
                            </td>
                            <td
                              style={{
                                color:
                                  data.reqType == "bonusCredit"
                                    ? "#3ea113"
                                    : "yellow",
                                fontWeight: "bold",
                              }}
                            >
                              ₹{data.amount}
                              <div
                                style={{
                                  fontSize: "10px",
                                  paddingTop: "3px",
                                  color:
                                    data.reqType == "bonusCredit"
                                      ? "#3ea113"
                                      : "yellow",
                                }}
                              >
                                Closing bal: {data.bonusClosingBalance}
                              </div>
                            </td>
                            <td
                              style={{
                                color:
                                  data.reqType == "bonusCredit"
                                    ? "#3ea113"
                                    : "yellow",
                                fontWeight: "bold",
                              }}
                            >
                              {data.status}
                            </td>
                            <td>{dateFormat(data.createdAt)}</td>
                            <td
                              style={{
                                color:
                                  data.reqType == "bonusCredit"
                                    ? "#3ea113"
                                    : "yellow",
                              }}
                            >
                              {data?.reqType}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#14213d",
                    padding: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    marginTop: "15px",
                  }}
                >
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          </div>
          {numberOfPages > 1 && (
            <div className="mt-4">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                pageLinkStyle={{ color: "#fff" }}
                activeLinkStyle={{
                  backgroundColor: "#00ff00",
                  borderColor: "#00ff00",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUpBonus;
