import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as XLSX from "xlsx";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { PiPrinterDuotone } from "react-icons/pi";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const $ = require("jquery");
$.Datatable = require("datatables.net");

const DepositReport = ({ isUserNumberVisible }) => {
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");

  const [user, setUser] = useState([]);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  let [limit, setLimit] = useState(10);
  const setpageLimit = (event) => {
    setLimit(event.target.value);
    setPageNumber(0);
  };

  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [startDate, setStartDate] = useState(
    new DateObject().subtract(4, "days")
  );
  const [endDate, setEndDate] = useState(new DateObject().add(4, "days"));

  useEffect(() => {
    axios.get(baseUrl + "settings/data").then((res) => {
      setCompanyName(res.data.companyName);
      setCompanyMobile(res.data.companyMobile);
      setCompanyAddress(res.data.companyAddress);
    });
  }, []);

  const profle = () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };
    axios
      .get(
        `${baseUrl}txn/depositreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,
        { headers }
      )
      .then((res) => {
        console.log("DEPOAIT DATAYAUI===", res.data);

        setUser(res.data.datefind);
        setNumberOfPages(res.data.totalPages);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const createDownloadData = () => {
    const table1 = [
      {
        A: "Id",
        B: "UserName",
        C: "PhoneNumber",
        D: "Deposit Amount",
        E: "Deposit Status",
      },
    ];
    user.forEach((row) => {
      table1.push({
        A: row._id,
        B: row.userId ? row.userId.name : "",
        C:
          row.userId && isUserNumberVisible
            ? row.userId.phone
            : "+91XXXXXXXXXX",
        D: row.amount ? row.amount : "",
        E: row.status ? row.status : "",
      });
    });
    const ws = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, "DepositReport.xlsx");
  };

  // function downloadPDF() {
  //   const doc = new jsPDF();

  //   // Table headers
  //   const columns = [
  //     "Id",
  //     "UserName",
  //     "PhoneNumber",
  //     "Deposit Amount",
  //     "Deposit Status",
  //   ];
  //   let startY = 10; // Starting Y position for drawing text

  //   // Draw the table headers
  //   doc.setFontSize(10);
  //   columns.forEach((col, index) => {
  //     doc.text(col, 10 + index * 35, startY); // Evenly space the headers
  //   });

  //   // Dynamically render the user data rows
  //   user?.forEach((row, index) => {
  //     const yPosition = startY + 10 + index * 10; // Adjust row spacing
  //     const idText = row._id || "";
  //     const userNameText = row.userId ? row.userId.name : "";
  //     const phoneNumberText =
  //       row.userId && isUserNumberVisible
  //         ? String(row.userId.phone)
  //         : "+91XXXXXXXXXX";
  //     const depositAmountText = String(row.amount) || "";
  //     const depositStatusText = row.status || "";

  //     // Render each cell's data in respective columns
  //     doc.text(idText, 10, yPosition);
  //     doc.text(userNameText, 65, yPosition);
  //     doc.text(phoneNumberText, 100, yPosition);
  //     doc.text(depositAmountText, 140, yPosition);
  //     doc.text(depositStatusText, 180, yPosition);
  //   });

  //   // Save the generated PDF
  //   doc.save("DepositReport.pdf");
  // }

  const downloadPDF = () => {
    // Prepare table data
    let table1 = user?.map((row) => ({
      A: row._id || "",
      B: row.userId ? row.userId.name : "",
      C:
        row.userId && isUserNumberVisible
          ? String(row.userId.phone)
          : "+91XXXXXXXXXX",
      D: row.amount ? String(row.amount) : "",
      E: row.status || "",
    }));

    // Generate PDF
    const doc = new jsPDF();
    const columns = [
      { header: "Id", dataKey: "A" },
      { header: "UserName", dataKey: "B" },
      { header: "PhoneNumber", dataKey: "C" },
      { header: "Deposit Amount", dataKey: "D" },
      { header: "Deposit Status", dataKey: "E" },
    ];

    // Use autoTable to draw the table
    doc.autoTable({
      head: [columns.map((col) => col.header)], // Pass only column headers
      body: table1.map((row) => [row.A, row.B, row.C, row.D, row.E]),
      startY: 20, // Set the starting point for the table
    });

    // Save the generated PDF
    doc.save("DepositReport.pdf");
  };

  const printInvoice = (invoiceData) => {
    const printWindow = window.open("", "", "width=800,height=600");
    // const depositBy = invoiceData.status === "Bonus by Admin"
    //   ? "Admin"
    //   : invoiceData.actionBy && invoiceData.actionBy.name
    //     ? invoiceData.actionBy.name
    //     : "None";

    printWindow.document.write(`
      <html>
        <head>
          <title>Deposit Invoice</title>
          <style>
            body {
              font-family: 'Arial', sans-serif;
              margin: 20px;
              color: #333;
            }
            h2 {
              color: #007bff;
              text-align: center;
            }
            p {
              font-size: 14px;
              line-height: 1.6;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f8f9fa;
              color: #333;
              font-weight: bold;
            }
            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
            tr:hover {
              background-color: #ddd;
            }
            .center-text {
              text-align: center;
              margin-top: 20px;
              font-size: 15px;
              color: #1a151c;
            }
          </style>
        </head>
        <body>
          <h2>Deposit Invoice</h2>
          <p><strong>Company:</strong> ${CompanyName}</p>
          <p><strong>Mobile:</strong> ${CompanyMobile}</p>
          <p><strong>Address:</strong> ${CompanyAddress}</p>
          <table>
            <tr>
              <th>Transaction ID</th>
              <td>${invoiceData._id}</td>
            </tr>
            <tr>
              <th>User Name</th>
              <td>${invoiceData.userId ? invoiceData.userId.name : "N/A"}</td>
            </tr>
            <tr>
              <th>Mobile</th>
              <td>${invoiceData.userId ? invoiceData.userId.phone : "N/A"}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>${invoiceData.amount} INR</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>${invoiceData.status}</td>
            </tr>
            <tr>
              <th>Order ID</th>
              <td>${invoiceData.orderId}</td>
            </tr>
            <tr>
              <th>Transaction ID</th>
              <td>${invoiceData.orderToken}</td>
            </tr>
            <tr>
              <th>Payment Mode</th>
              <td>${invoiceData.paymentGateway || "None"}</td>
            </tr>
            <tr>
              <th> Invoice Date</th>
              <td>${new Date(invoiceData.createdAt).toLocaleDateString()}</td>
            </tr>
            <tr>
                                <th> Total :</th>
                                <td>${
                                  invoiceData.amount
                                    ? "Rs. " + invoiceData.amount
                                    : ""
                                }</td>
                              </tr>
            
          </table>
          <div class="center-text">
            ----*THIS IS A COMPUTER-GENERATED INVOICE. NO SIGNATURE REQUIRED*----
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  useEffect(() => {
    profle();
  }, [pageNumber, limit, startDate, endDate]);

  return (
    <div className="row ">
      <div className="col-12 grid-margin">
        <div
          className="card"
          // style={{ borderRadius: "10px", backgroundColor: "#1f2937" }}
        >
          <div
            className="card-body"
            // style={{ backgroundColor: "#0d1b2a", borderRadius: "10px" }}
          >
            <h4
              className="card-title mt-4"
              // style={{ color: "white" }}
            >
              Deposit Reports
            </h4>
            <div className="download-button">
              <button
                onClick={() => {
                  createDownloadData();
                }}
                className="btn btn-primary "
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#0056b3";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#007bff";
                  e.target.style.transform = "scale(1)";
                }}
              >
                <RiFileExcel2Line style={{ fontSize: "24px" }} />
              </button>

              {/* pdf download button */}
              <button
                onClick={() => {
                  downloadPDF();
                }}
                className="btn btn-danger"
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#841f29";
                  e.target.style.transform = "scale(1.05)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#dc3545";
                  e.target.style.transform = "scale(1)";
                }}
              >
                <FaRegFilePdf style={{ fontSize: "24px" }} />
              </button>
            </div>

            <div className="d-flex flex-wrap justify-content-start justify-content-sm-between  align-items-center mt-3 mb-3 w-100">
              {/* Start Date Picker */}
              <div
                style={{ marginRight: "10px" }}
                className="commoncss-custom-with-datediv"
              >
                <label
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  Start Date:
                </label>
                <DatePicker
                  value={startDate}
                  onChange={setStartDate}
                  style={{
                    backgroundColor: "#f8f9fb",
                    color: "#black",
                    borderRadius: "5px",
                    padding: "5px",
                    minWidth: "120px",
                    width: "100%",
                    height: "40px",
                  }}
                />
              </div>

              {/* End Date Picker */}
              <div
                style={{ marginRight: "10px" }}
                className="commoncss-custom-with-datediv"
              >
                <label
                  style={{
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  End Date:
                </label>
                <DatePicker
                  value={endDate}
                  onChange={setEndDate}
                  style={{
                    backgroundColor: "#f8f9fb",
                    color: "#black",
                    borderRadius: "5px",
                    padding: "5px",
                    minWidth: "120px",
                    width: "100%",
                    height: "40px",
                  }}
                />
              </div>

              <select
                // className="form-control col-sm-1 bg-light text-dark"
                className="commoncss-custom-with-datediv"
                id="pagelimit"
                name="pagelimit"
                onChange={setpageLimit}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#f8f9fb",
                  color: "black",
                  minWidth: "120px",
                  height: "40px",
                  marginBottom: "4px",
                }}
              >
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
            {user.length > 0 ? (
              <>
                <div className="table-responsive mt-3">
                  <table
                    className="table text-light table-hover"
                    // style={{ borderRadius: "10px", color: "#fff" }}
                  >
                    <thead>
                      <tr
                      // tyle={{ backgroundColor: "#14213d" }}
                      >
                        <th>#</th>
                        <th>Transaction_Id</th>
                        {isUserNumberVisible && <th>Mob.No</th>}
                        <th>User</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Description</th>
                        <th>OrderID</th>
                        <th>TxnID</th>
                        <th>Payment Mode</th>
                        <th>Invoice Date</th>
                        <th>Action</th>
                        <th>Print Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.map((data, key) => (
                        <tr
                          key={data._id}
                          style={{ borderBottom: "1px solid #e5e7eb" }}
                        >
                          <td>
                            {key +
                              1 +
                              +(pageNumber ? pageNumber - 1 : 0) * limit}
                          </td>
                          <td>{data._id}</td>
                          {isUserNumberVisible && (
                            <td>{data.userId ? data.userId.phone : ""}</td>
                          )}
                          <td>
                            {data.userId && (
                              <Link
                                className="nameButton badge"
                                to={`/user/view_user/${data.userId._id}`}
                                // style={{
                                //   backgroundColor: "#e5e7eb",
                                //   color: "#1f2937",
                                //   fontWeight: "bold",
                                //   borderRadius: "20px",
                                //   padding: "5px 15px",
                                //   textTransform: "capitalize",
                                //   fontSize: "14px",
                                // }}
                              >
                                {data.userId && data.userId.accountHolderName
                                  ? data.userId.accountHolderName
                                  : data.userId.name}
                                {/* {data.userId.name} */}
                              </Link>
                            )}
                          </td>
                          <td
                            style={{
                              color:
                                data.status === "PAID" ||
                                data.status === "success" ||
                                data.status === "Credited" ||
                                data.status === "Deposit Credited"
                                  ? "#3ea113"
                                  : data.status === "Pending"
                                  ? "#ffcc00"
                                  : "#ff0000",
                              fontWeight: "bold",
                            }}
                          >
                            ₹{data.amount}
                          </td>
                          <td
                            style={{
                              color:
                                data.status === "PAID" ||
                                data.status === "success" ||
                                data.status === "Credited" ||
                                data.status === "Deposit Credited"
                                  ? "#3ea113"
                                  : data.status === "Pending"
                                  ? "#ffcc00"
                                  : "#ff0000",
                              fontWeight: "bold",
                            }}
                          >
                            {data.status}
                          </td>
                          <td style={{ fontWeight: "bold", fontSize: "13px" }}>
                            {data.reqType}
                          </td>
                          <td>{data.orderId}</td>
                          <td>{data.orderToken}</td>
                          <td>{data.paymentGateway || "None"}</td>
                          <td>{dateFormat(data.createdAt)}</td>
                          <td
                            style={{
                              color: data.actionBy ? "#00ff00" : "yellow",
                            }}
                          >
                            {data.actionBy ? data.actionBy.name : "None"}
                          </td>
                          <td>
                            <button
                              onClick={() => printInvoice(data)}
                              className="btn btn-primary mx-1"
                              style={{
                                fontSize: "20px",
                                borderRadius: "10px",
                                width: "60px",
                              }}
                            >
                              <PiPrinterDuotone />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#14213d",
                  padding: "20px",
                  borderRadius: "10px",
                  color: "#fff",
                  marginTop: "15px",
                }}
              >
                <h4>No Data Found</h4>
              </div>
            )}
          </div>
        </div>
        {numberOfPages > 1 && (
          <div className="mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              pageLinkStyle={{ color: "#fff" }}
              activeLinkStyle={{
                backgroundColor: "#00ff00",
                borderColor: "#00ff00",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DepositReport;

// 7597461255
